import { Route , Redirect} from "react-router-dom";

// Define PrivateRoute component
const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/signin" />
      }
    />
  );

  export default PrivateRoute;