import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { signupValidationSchema } from "../../schema";
import {
  GET_COUNTRIES,
  USER_LOGIN,
  USER_REGISTRATION,
} from "../../constants/endpoints";
import AxiosService, { AxiosServiceLoaderless } from "../../constants/api";
import { toast } from "react-toastify";
import logo from "../../assets/static/images/logo.png";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  card: {
    overflow: "visible",
  },
  session: {
    position: "relative",
    zIndex: 4000,
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  background: {
    backgroundColor: theme.palette.primary.main,
  },
  content: {
    padding: `40px ${theme.spacing(1)}px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "1 0 auto",
    flexDirection: "column",
    minHeight: "100%",
    textAlign: "center",
  },
  wrapper: {
    flex: "none",
    maxWidth: "400px",
    width: "100%",
    margin: "0 auto",
  },
  fullWidth: {
    width: "100%",
  },
  logo: {
    display: "flex",
    flexDirection: "column",
  },
}));

const Signup = () => {
  const classes = useStyles();
  const history = useHistory();
  const [countries, setCountries] = useState([]);
  // useEffect(() => {
  //   const isLogin = localStorage.getItem("auth");

  //   if (isLogin) {
  //     history.push("/");
  //   }
  // }, []);

  const getCountries = async () => {
    try {
      const res = await AxiosServiceLoaderless.post(GET_COUNTRIES);
      if (res.data.status == "OK") {
        setCountries(res?.data?.response);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  //   handle Registration
  const handleRegistration = async (data) => {
    try {
      const res = await AxiosService.post(USER_REGISTRATION, data);
      toast.success("Registered successfully.");
      history.push("/signin");
    } catch (error) {
      toast.error(error.response.data.message);
      console.log("Error", error);
    }
  };

  // Formik form handling
  const formik = useFormik({
    initialValues: {
      f_name: "",
      l_name: "",
      email: "",
      password: "",
      phone: "",
      c_code: "",
      terms: false,
    },
    validationSchema: signupValidationSchema,
    onSubmit: (values) => {
      delete values.terms;
      handleRegistration(values);
    },
  });

  return (
    <div className={classNames(classes.session, classes.background)}>
      <div className={classes.content}>
        <div className={classes.wrapper}>
          <Card>
            <CardContent>
              <form onSubmit={formik.handleSubmit}>
                <div
                  className={classNames(classes.logo, `text-xs-center pb-xs`)}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      my: 2,
                    }}
                  >
                    <img
                      src={logo}
                      alt=""
                      className="block"
                      height={100}
                      width={100}
                    />
                  </Box>
                  {/* <img
                    src={`${process.env.PUBLIC_URL}/static/images/logo-dark.svg`}
                    alt=""
                  /> */}
                  <Typography variant="caption">
                    Create an app id to continue.
                  </Typography>
                </div>
                <TextField
                  id="f_name"
                  name="f_name"
                  label="First Name"
                  className={classes.textField}
                  fullWidth
                  margin="normal"
                  value={formik.values.f_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.f_name && Boolean(formik.errors.f_name)}
                  helperText={formik.touched.f_name && formik.errors.f_name}
                />
                <TextField
                  id="l_name"
                  name="l_name"
                  label="Last Name"
                  className={classes.textField}
                  fullWidth
                  margin="normal"
                  value={formik.values.l_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.l_name && Boolean(formik.errors.l_name)}
                  helperText={formik.touched.l_name && formik.errors.l_name}
                />

                <TextField
                  id="phone"
                  name="phone"
                  label="Phone No."
                  className={classes.textField}
                  fullWidth
                  margin="normal"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  onKeyDown={(e) => {
                    if (
                      !/^[0-9]$/i.test(e.key) &&
                      e.key !== "Backspace" &&
                      e.key !== "Delete" &&
                      e.key !== "ArrowLeft" &&
                      e.key !== "ArrowRight"
                    ) {
                      e.preventDefault();
                    }
                  }}
                />

                {/* Country Code Select */}
                <TextField
                  id="c_code"
                  name="c_code"
                  select
                  label=""
                  fullWidth
                  margin="normal"
                  value={formik.values.c_code}
                  onChange={formik.handleChange}
                  variant="outlined"
                  error={formik.touched.c_code && Boolean(formik.errors.c_code)}
                  helperText={formik.touched.c_code && formik.errors.c_code}
                  SelectProps={{
                    native: true,
                  }}
                >
                  <option value="">Select Country Code</option>
                  {countries?.map((country) => (
                    <option key={country.code} value={country.code}>
                      {`${country.code} (${country.name})`}
                    </option>
                  ))}
                </TextField>

                <TextField
                  id="email"
                  name="email"
                  label="Email address"
                  className={classes.textField}
                  fullWidth
                  margin="normal"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
                <TextField
                  id="password"
                  name="password"
                  label="Password"
                  className={classes.textField}
                  type="password"
                  fullWidth
                  margin="normal"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      name="terms"
                      checked={formik.values.terms}
                      onChange={formik.handleChange}
                    />
                  }
                  label="I have read and agree to the terms of service."
                  className={classes.fullWidth}
                />
                {formik.touched.terms && formik.errors.terms && (
                  <Typography
                    color="error"
                    variant="body2"
                    style={{ paddingBottom: "8px" }}
                  >
                    {formik.errors.terms}
                  </Typography>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                >
                  Create your account
                </Button>
                <div className="pt-1 text-xs-center">
                  {/* <Link to="/forgot">
                    <Button>Forgot password?</Button>
                  </Link> */}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Link to="/signin">
                    <Button>Access your account.</Button>
                  </Link>
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Signup;
