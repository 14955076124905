import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  TextField,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  MenuItem,
  Select,
  Button,
  Grid,
  Box,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import ClearIcon from "@material-ui/icons/Clear";
import { IconButton, InputAdornment } from "@material-ui/core";

const generateValidationSchema = (fields) => {
  const shape = {};

  fields.forEach((field) => {
    if (field.type === "daterange") {
      // Add validation for `from` and `to` fields separately
      if (field.from) {
        let fromValidator = Yup.string();
        if (field.from.required) {
          fromValidator = fromValidator.required(
            `${field.from.label} is required`
          );
        }
        shape[field.from.name] = fromValidator;
      }
      if (field.to) {
        let toValidator = Yup.string();
        if (field.to.required) {
          toValidator = toValidator.required(`${field.to.label} is required`);
        }
        shape[field.to.name] = toValidator;
      }
    } else {
      // General field validation
      let validator = Yup.string();
      if (field.required) {
        validator = validator.required(`${field.label} is required`);
      }
      shape[field.name] = validator;
    }
  });

  return Yup.object().shape(shape);
};

const renderField = (fieldConfig) => {
  switch (fieldConfig.type) {
    case "radio":
      return (
        <Grid item xs={12} sm={6} md={4} key={fieldConfig.name}>
          <FormControl component="fieldset" fullWidth>
            <Field name={fieldConfig.name}>
              {({ field, form }) => (
                <RadioGroup
                  {...field}
                  value={form.values[fieldConfig.name] || ""}
                  onChange={(event) =>
                    form.setFieldValue(fieldConfig.name, event.target.value)
                  }
                  sx={{ display: "flex", flexDirection: "row" }}
                >
                  {fieldConfig.options && fieldConfig.options.length > 0 ? (
                    fieldConfig.options.map((option) => (
                      <FormControlLabel
                        key={option.value}
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                      />
                    ))
                  ) : (
                    <FormControlLabel
                      value=""
                      control={<Radio />}
                      label="No options available"
                    />
                  )}
                </RadioGroup>
              )}
            </Field>
          </FormControl>
        </Grid>
      );

    case "select":
      return (
        <Grid item xs={12} sm={6} md={4} key={fieldConfig.name}>
          <FormControl fullWidth>
            <Field name={fieldConfig.name}>
              {({ field, form }) => (
                <Select
                  {...field}
                  value={form.values[fieldConfig.name] || ""}
                  onChange={(event) =>
                    form.setFieldValue(fieldConfig.name, event.target.value)
                  }
                  variant="outlined"
                  size="small"
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected === "") {
                      return (
                        <span style={{ color: "#aaa" }}>
                          {fieldConfig.label}
                        </span>
                      );
                    }
                    const selectedOption = fieldConfig.options.find(
                      (option) => option.value === selected
                    );
                    return selectedOption ? selectedOption.label : selected;
                  }}
                >
                  <MenuItem value="">
                    <span style={{ color: "#aaa" }}>{fieldConfig.label}</span>
                  </MenuItem>
                  {fieldConfig.options && fieldConfig.options.length > 0 ? (
                    fieldConfig.options.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">
                      <em>No options available</em>
                    </MenuItem>
                  )}
                </Select>
              )}
            </Field>
          </FormControl>
        </Grid>
      );

    case "date":
      return (
        <>
          <Grid
            container
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
            xl={4}
            key={fieldConfig.name}
          >
            <FormControl
              fullWidth
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FormLabel sx={{ marginRight: 2 }}>{fieldConfig.label}</FormLabel>
              <Field name={fieldConfig.name}>
                {({ field, form }) => {
                  const hasValue = Boolean(form.values[fieldConfig.name]);

                  const handleClear = () => {
                    form.setFieldValue(fieldConfig.name, "");
                  };

                  return (
                    <TextField
                      {...field}
                      type="date"
                      variant="outlined"
                      size="small"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        endAdornment: hasValue && (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              onClick={handleClear}
                              size="small"
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      placeholder={fieldConfig.label}
                      error={
                        form.touched[fieldConfig.name] &&
                        Boolean(form.errors[fieldConfig.name])
                      }
                      helperText={
                        form.touched[fieldConfig.name] &&
                        form.errors[fieldConfig.name]
                      }
                    />
                  );
                }}
              </Field>
            </FormControl>
          </Grid>
        </>
      );

    case "daterange":
      return (
        <>
          <Grid
            container
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
            xl={4}
            key={fieldConfig.from.name}
          >
            <FormControl
              fullWidth
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FormLabel sx={{ marginRight: 2 }}>
                {fieldConfig.from.label}
              </FormLabel>
              <Field name={fieldConfig.from.name}>
                {({ field, form }) => {
                  const hasValue = Boolean(form.values[fieldConfig.from.name]);
                  const handleClear = () => {
                    form.setFieldValue(fieldConfig.from.name, "");
                  };

                  return (
                    <TextField
                      {...field}
                      type="date"
                      variant="outlined"
                      size="small"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        endAdornment: hasValue && (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              onClick={handleClear}
                              size="small"
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      placeholder={fieldConfig.from.label}
                      error={
                        form.touched[fieldConfig.from.name] &&
                        Boolean(form.errors[fieldConfig.from.name])
                      }
                      helperText={
                        form.touched[fieldConfig.from.name] &&
                        form.errors[fieldConfig.from.name]
                      }
                    />
                  );
                }}
              </Field>
            </FormControl>
          </Grid>

          <Grid
            container
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
            xl={4}
            key={fieldConfig.to.name}
          >
            <FormControl
              fullWidth
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FormLabel sx={{ marginRight: 2 }}>
                {fieldConfig.to.label}
              </FormLabel>
              <Field name={fieldConfig.to.name}>
                {({ field, form }) => {
                  const hasValue = Boolean(form.values[fieldConfig.to.name]);
                  const handleClear = () => {
                    form.setFieldValue(fieldConfig.to.name, "");
                  };

                  return (
                    <TextField
                      {...field}
                      type="date"
                      variant="outlined"
                      size="small"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        endAdornment: hasValue && (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              onClick={handleClear}
                              size="small"
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      placeholder={fieldConfig.to.label}
                      error={
                        form.touched[fieldConfig.to.name] &&
                        Boolean(form.errors[fieldConfig.to.name])
                      }
                      helperText={
                        form.touched[fieldConfig.to.name] &&
                        form.errors[fieldConfig.to.name]
                      }
                    />
                  );
                }}
              </Field>
            </FormControl>
          </Grid>
        </>
      );

    default:
      return (
        <Grid item xs={12} sm={6} md={4} key={fieldConfig.name}>
          <FormControl fullWidth>
            <Field name={fieldConfig.name}>
              {({ field, form }) => (
                <TextField
                  {...field}
                  type={fieldConfig.type || "text"}
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder={fieldConfig.label}
                  error={
                    form.touched[fieldConfig.name] &&
                    Boolean(form.errors[fieldConfig.name])
                  }
                  helperText={
                    form.touched[fieldConfig.name] &&
                    form.errors[fieldConfig.name]
                  }
                />
              )}
            </Field>
          </FormControl>
        </Grid>
      );
  }
};

const SearchForm = ({
  fields,
  onSubmit,
  initialValues,
  handleCsv,
  isDownloadable,
}) => {
  const validationSchema = generateValidationSchema(fields);

  const getInitialValues = () => {
    const initialVals = {};
    fields.forEach((field) => {
      initialVals[field.name] =
        initialValues && initialValues[field.name]
          ? initialValues[field.name]
          : "";
    });
    return initialVals;
  };

  const handleFormSubmission = (info) => {
    onSubmit(info);
  };

  const handleResetForm = (handleReset) => {
    handleReset();
    onSubmit(false);
  };

  return (
    <Box sx={{ px: 2 }}>
      <Formik
        initialValues={getInitialValues()}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmission}
      >
        {({ handleSubmit, handleReset, dirty }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {fields.map((field) => renderField(field))}
              <Grid container item xs={12} spacing={2}>
                <Grid item>
                  <Button type="submit" variant="contained" color="primary">
                    Search
                  </Button>
                </Grid>
                {handleCsv && (
                  <Grid item>
                    <Button
                      onClick={handleCsv}
                      variant="contained"
                      color="primary"
                      disabled={!isDownloadable}
                    >
                      Download Csv
                    </Button>
                  </Grid>
                )}
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!dirty}
                    onClick={() => handleResetForm(handleReset)}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default SearchForm;
