// Pages
import {
  AppBar,
  Autocomplete,
  Avatars,
  BackendError,
  Badges,
  Blank,
  ButtonNavigation,
  Buttons,
  Calendar,
  Cards,
  Charts,
  Chat,
  Chips,
  Detail,
  Dialogs,
  Dividers,
  Drawers,
  Editor,
  ExpansionPanels,
  Google,
  GridList,
  Home,
  Invoice,
  Leaflet,
  Lists,
  Lockscreen,
  Media,
  Menus,
  Messages,
  NotFound,
  Paper,
  PasswordReset,
  Pickers,
  PricingPage,
  Products,
  Progress,
  SelectionControls,
  Selects,
  Signin,
  Signup,
  Snackbars,
  Social,
  Steppers,
  Tables,
  Tabs,
  Taskboard,
  TextFields,
  TimelinePage,
  Tooltips,
  Widgets,
} from "./pages";

import AppsIcon from "@material-ui/icons/Apps";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import EqualizerIcon from "@material-ui/icons/Equalizer";
// Icons
import ExploreIcon from "@material-ui/icons/Explore";
import FaceIcon from "@material-ui/icons/Face";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import Looks3Icon from "@material-ui/icons/Looks3";
import Looks4Icon from "@material-ui/icons/Looks4";
import NavigationIcon from "@material-ui/icons/Navigation";
import PagesIcon from "@material-ui/icons/Pages";
import PersonsIcon from "@material-ui/icons/Person";
import PeopleIcon from "@material-ui/icons/People";
import PhotoIcon from "@material-ui/icons/Photo";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import ActiveUsers from "./pages/Users/ActiveUsers";
import AddUser from "./pages/Users/AddUser";
import DeletedUsers from "./pages/Users/DeletedUsers";
import Profile from "./pages/Profile/Profile";
import EditUser from "./pages/Users/EditUser";
import { AssessmentSharp } from "@material-ui/icons";
import AllAssessments from "./components/Assessment/AllAssessments";
import AssessmentDetail from "./components/Assessment/AssessmentDetail";
import ActiveUsersDetail from "./pages/Users/ActiveUserDetail";
import UserAssessmentDetail from "./pages/Users/UsersAssessment";

export default {
  items: [
    // {
    //   path: "/",
    //   name: "Home",
    //   type: "link",
    //   icon: ExploreIcon,
    //   component: Home,
    // },
    {
      path: "/profile",
      name: "My Profile",
      type: "link",
      icon: PersonsIcon,
      component: Profile,
    },

    {
      path: "/users",
      name: "Users",
      type: "submenu",
      icon: PeopleIcon,
      children: [
        {
          path: "/view",
          name: "View Users",
          component: ActiveUsers,
        },
        {
          path: "/assessment",
          name: "Assessments",
          component: AllAssessments,
        },
        {
          path: "/add-user",
          name: "Add User",
          component: AddUser,
        },
      ],
    },

    // {
    //   path:"/assesments",
    //   name: 'asssesments',
    //   type: 'link',
    //   icon: ViewColumnIcon,
    //   component: Assessment
    // },
    {
      path: "/edit-user",
      name: "editUser",
      type: "link",
      icon: ViewColumnIcon,

      // icon: ,
      component: EditUser,
    },
    {
      path: "/assessment-detail/:id",
      name: "assessmentDetail",
      type: "link",
      icon: ViewColumnIcon,

      // icon: ,
      component: AssessmentDetail,
    },
    {
      path: "/user-assessment-detail/:id",
      name: "activeuserdetail",
      type: "link",
      icon: ViewColumnIcon,

      // icon: ,
      component: UserAssessmentDetail,
    },
  ],
};
