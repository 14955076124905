import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const useStyles = makeStyles((theme) => ({
  footer: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}));

const PaginationFooter = ({ currentPage, totalPages, onPageChange }) => {
  const classes = useStyles();

  const handleChange = (event, value) => {
    onPageChange(value);
  };

  return (
    <div className={classes.footer}>
      <Stack spacing={2}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handleChange}
          shape="rounded"
          color="primary"
        />
      </Stack>
    </div>
  );
};

export default PaginationFooter;
