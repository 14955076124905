// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendar-toolbar {
  padding-left: 4px!important;
  padding-right: 4px!important;
}
.rbc-time-view, .rbc-month-view {
  border: 0;
}
.rbc-header {
  font-weight: 500;
  text-align: right;
}
.rbc-header + .rbc-header {
  border-left: 0;
}

@media (max-width: 959.95px) {
  .rbc-month-view {
    height: 100%;
    height: calc(100vh - 160px);
  }
}
@media (max-width: 599px) {
  .rbc-month-view {
    width: 100%;
    overflow-y: hidden;
    min-height: .01%;
    overflow-x: auto;
  }
  .rbc-month-view > * {
    width: 700px;
  }
}
@media (min-width: 600px) {
  .rbc-month-view {
    height: 100%;
    height: calc(100vh - 176px);
  }
}
@media (min-width: 960px) {
  .rbc-month-view {
    height: 100%;
    height: calc(100vh - 192px);
  }
}
.rbc-event {
  background-color: #3f51b5;
  border-radius: 4px;
  font-size: 12px;
}
.rbc-day-slot .rbc-event {
  border-color:#ffffff;
}
.theme-dark .rbc-time-content,
.theme-dark .rbc-header,
.theme-dark .rbc-month-row + .rbc-month-row,
.theme-dark .rbc-day-bg + .rbc-day-bg,
.theme-dark .rbc-time-content > * + * > *,
.theme-dark .rbc-day-slot .rbc-time-slot,
.theme-dark .rbc-timeslot-group,
.theme-dark .rbc-time-header-content
 {
  border-color:rgba(0, 0, 0, 0.12);
}
.theme-dark .rbc-off-range-bg {
  background: rgba(6, 6, 6, 0.10);
}`, "",{"version":3,"sources":["webpack://./src/pages/Apps/calendar.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,4BAA4B;AAC9B;AACA;EACE,SAAS;AACX;AACA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,YAAY;IACZ,2BAA2B;EAC7B;AACF;AACA;EACE;IACE,WAAW;IACX,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;EAClB;EACA;IACE,YAAY;EACd;AACF;AACA;EACE;IACE,YAAY;IACZ,2BAA2B;EAC7B;AACF;AACA;EACE;IACE,YAAY;IACZ,2BAA2B;EAC7B;AACF;AACA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,oBAAoB;AACtB;AACA;;;;;;;;;EASE,gCAAgC;AAClC;AACA;EACE,+BAA+B;AACjC","sourcesContent":[".calendar-toolbar {\n  padding-left: 4px!important;\n  padding-right: 4px!important;\n}\n.rbc-time-view, .rbc-month-view {\n  border: 0;\n}\n.rbc-header {\n  font-weight: 500;\n  text-align: right;\n}\n.rbc-header + .rbc-header {\n  border-left: 0;\n}\n\n@media (max-width: 959.95px) {\n  .rbc-month-view {\n    height: 100%;\n    height: calc(100vh - 160px);\n  }\n}\n@media (max-width: 599px) {\n  .rbc-month-view {\n    width: 100%;\n    overflow-y: hidden;\n    min-height: .01%;\n    overflow-x: auto;\n  }\n  .rbc-month-view > * {\n    width: 700px;\n  }\n}\n@media (min-width: 600px) {\n  .rbc-month-view {\n    height: 100%;\n    height: calc(100vh - 176px);\n  }\n}\n@media (min-width: 960px) {\n  .rbc-month-view {\n    height: 100%;\n    height: calc(100vh - 192px);\n  }\n}\n.rbc-event {\n  background-color: #3f51b5;\n  border-radius: 4px;\n  font-size: 12px;\n}\n.rbc-day-slot .rbc-event {\n  border-color:#ffffff;\n}\n.theme-dark .rbc-time-content,\n.theme-dark .rbc-header,\n.theme-dark .rbc-month-row + .rbc-month-row,\n.theme-dark .rbc-day-bg + .rbc-day-bg,\n.theme-dark .rbc-time-content > * + * > *,\n.theme-dark .rbc-day-slot .rbc-time-slot,\n.theme-dark .rbc-timeslot-group,\n.theme-dark .rbc-time-header-content\n {\n  border-color:rgba(0, 0, 0, 0.12);\n}\n.theme-dark .rbc-off-range-bg {\n  background: rgba(6, 6, 6, 0.10);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
