// user api
// export const USER_LOGIN = "/userapi/phlogin";
export const USER_LOGIN = "/userapi/login";
export const USER_REGISTRATION = "/userapi/phregistration";
export const UPDATE_USER_PROFILE = "/userapi/updateuserprofile";

// get aLL users
export const GET_ALL_USERS = "/userapi/getusersfr";

// country
export const GET_COUNTRIES = "/commonapi/getcountries";

// Assessment
export const GET_USERS_ASSESSMENT = "/userassessmentapi/getassessmentsfr";
export const GET_USER_ASSESSMENT = "/userassessmentapi/getassessmentsfr";
