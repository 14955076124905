// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `quill-editor {
  display: block;
  border: 0;
}
.ql-container.ql-snow,
.ql-toolbar.ql-snow {
  border: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/Forms/editor.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,SAAS;AACX;AACA;;EAEE,SAAS;AACX","sourcesContent":["quill-editor {\n  display: block;\n  border: 0;\n}\n.ql-container.ql-snow,\n.ql-toolbar.ql-snow {\n  border: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
