import axios from "axios";

const config = {
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 25000,
  headers: {
    "Content-Type": "application/json",
  },
};

// axios instance creation
export const AxiosServiceLoaderless = axios.create(config);
const AxiosService = axios.create(config);

// request interceptor
AxiosService.interceptors.request.use(
  async (config) => {
    document.querySelector(".loader").style.display = "flex";

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response interceptor
AxiosService.interceptors.response.use(
  async (response) => {
    // spinning hide
    document.querySelector(".loader").style.display = "none";

    return response;
  },

  async (error) => {
    document.querySelector(".loader").style.display = "none";

    return Promise.reject(error);
  }
);

export default AxiosService;
