import { Route, Redirect } from "react-router-dom";


// Define PublicRoute component
const PublicRoute = ({ component: Component, isAuthenticated, ...rest }) =>
   (
  
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );


  export default PublicRoute;