import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { GET_USER_ASSESSMENT } from "../../constants/endpoints";
import AxiosService, { AxiosServiceLoaderless } from "../../constants/api";
import { useParams } from "react-router-dom";
import PaginationFooter from "../../components/PaginationFooter/PaginationFooter";
import CircularProgress from "@mui/material/CircularProgress";
import ImageIcon from "@material-ui/icons/Image";

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    overflowX: "auto",
  },
  subroot: {
    margin: theme.spacing(2),
    overflowX: "auto",
  },
  table: {
    // marginRight:"20px"
  },
  tableContainer: {
    // maxHeight: "55vh", // Set the maximum height for the table body
    // overflowY: "auto", // Enable vertical scrolling
  },
  title: {
    flex: "0 0 auto",
    padding: "20px",
  },
  center: {
    alignItem: "center",
  },
  loadingCell: {
    textAlign: "center",
    padding: theme.spacing(2),
  },
  dialogImage: {
    width: "100%",
    minWidth: "300px",
    minHeight: "300px",
    height: "auto",
  },
});

const limit = parseInt(process.env.REACT_APP_TABLE_ROW_LIMIT) || 100;
const fallbackImage = "https://via.placeholder.com/600x400";

const ActiveUserDetail = ({ classes }) => {
  const history = useHistory();
  const { id } = useParams();
  const [imageSrc, setImageSrc] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({
    rows: [],
    currentPage: 1,
    nextOffset: 0,
    lastOffset: 0,
    limit: limit,
    totalPages: 0,
  });
  const auth = JSON.parse(localStorage.getItem("auth"));

  const getUserAssessment = async (payload) => {
    try {
      const { currentPage, ...payloadData } = payload;
      setLoading(true);
      const res = await AxiosServiceLoaderless.post(
        GET_USER_ASSESSMENT,
        payloadData
      );
      if (res.status === 200) {
        setData((prev) => ({
          ...prev,
          rows: res.data.response || [],
          nextOffset: res.data.next_offset || 0,
          lastOffset: res.data.last_offset || 0,
          totalPages: res.data.total_records
            ? Math.ceil(parseInt(res.data.total_records) / parseInt(data.limit))
            : 0,
          currentPage: currentPage,
        }));
      }
    } catch (error) {
      console.log("Error:", error);
      setData((prev) => ({
        ...prev,
        rows: [],
        nextOffset: 0,
        lastOffset: 0,
        totalPages: 0,
        currentPage: payload.currentPage,
        limit: limit,
      }));
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (pageNo) => {
    const payload = {
      api_key: auth?.api_key,
      user_id: id,
      limit: data.limit,
      offset: (pageNo - 1) * data.limit,
      currentPage: pageNo,
    };
    getUserAssessment(payload);
  };

  const handleImageClick = (src) => {
    setImageSrc(src);
    setOpen(true);
  };

  // const handleImageError = () => {
  //   setImageSrc(fallbackImage);
  // };

  useEffect(() => {
    const payload = {
      api_key: auth?.api_key,
      user_id: id,
      currentPage: 1,
      limit: data.limit,
      offset: data.offset,
    };
    getUserAssessment(payload);
  }, []);

  return (
    <Paper className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h4" id="tableTitle">
          User Assesment Detail
        </Typography>
      </div>
      <Paper className={classes.subroot}>
        {/* <Box className={classes.tableContainer}> */}
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Ass. ID</TableCell>
              <TableCell>User</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Question</TableCell>
              <TableCell>Remarks</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Image</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={7} className={classes.loadingCell}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {data?.rows?.length < 1 && (
                  <TableRow>
                    <TableCell colSpan={7} className={classes.loadingCell}>
                      <Typography variant="h6">No Record Found</Typography>
                    </TableCell>
                  </TableRow>
                )}
                {data?.rows?.map((row) => (
                  <TableRow key={row.assessment_id}>
                    <TableCell>{row.assessment_id}</TableCell>
                    <TableCell>{row.username}</TableCell>
                    <TableCell>{row.category}</TableCell>
                    <TableCell>{row.question}</TableCell>
                    <TableCell>{row.remarks}</TableCell>
                    <TableCell>{row.created}</TableCell>
                    <TableCell>
                      {row.image_path ? (
                        <Box sx={{ cursor: "pointer" }}>
                          <Avatar
                            alt={row.assessment_id}
                            src={`${row.image_path}`}
                            onClick={() =>
                              handleImageClick(`${row.image_path}`)
                            }
                            variant="rounded"
                          >
                            <ImageIcon />
                          </Avatar>
                        </Box>
                      ) : null}
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
        {/* </Box> */}
        <PaginationFooter
          currentPage={data.currentPage}
          totalPages={data.totalPages}
          onPageChange={handlePageChange}
        />
      </Paper>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md">
        <DialogContent>
          <img
            src={imageSrc}
            // alt="Assessment"
            // onError={handleImageError}
            className={classes.dialogImage}
          />
        </DialogContent>
      </Dialog>
    </Paper>
  );
};

export default withStyles(styles)(ActiveUserDetail);
