import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import {
  BackendError,
  Lockscreen,
  NotFound,
  PasswordReset,
  Signin,
  Signup,
} from "./pages";
import AppProvider from "./components/AppProvider/AppProvider";
import Dashboard from "./containers/Dashboard";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { render } from "react-dom";
import registerServiceWorker from "./registerServiceWorker";
import PublicRoute from "./routes/PublicRoute";
import PrivateRoute from "./routes/PrivateRoute";

// Main Index component
const Index = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const auth = localStorage.getItem("auth");
    if (auth) {
      setIsAuthenticated(true);
    }
  }, []);

  return (
    <AppProvider>
      <span className="loader loading-indicator">
        <div className="loading-spinner"></div>
      </span>
      <ToastContainer />
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Switch>
          <Route exact path="/404" component={NotFound} />
          <Route exact path="/500" component={BackendError} />
          <Route exact path="/Lockscreen" component={Lockscreen} />
          <PublicRoute
            exact
            path="/forgot"
            component={PasswordReset}
            isAuthenticated={isAuthenticated}
          />
          <PublicRoute
            exact
            path="/signin"
            component={() => <Signin setIsAuthenticated={setIsAuthenticated} />}
            isAuthenticated={isAuthenticated}
          />
          <PublicRoute
            exact
            path="/signup"
            component={Signup}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/"
            component={Dashboard}
            // component={() => <Dashboard setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} />}
            isAuthenticated={isAuthenticated}
          />
        </Switch>
      </BrowserRouter>
    </AppProvider>
  );
};

// Render the Index component
render(<Index />, document.getElementById("root"));

registerServiceWorker();

// import {
//   BackendError,
//   Lockscreen,
//   NotFound,
//   PasswordReset,
//   Signin,
//   Signup
// } from "./pages";
// import { BrowserRouter, Route, Switch } from "react-router-dom";

// import AppProvider from "./components/AppProvider/AppProvider";
// import Dashboard from "./containers/Dashboard";
// import React from "react";
// import registerServiceWorker from "./registerServiceWorker";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// import { render } from "react-dom";

// render(
//   <AppProvider>
//       <span className="loader loading-indicator">
//       <div className="loading-spinner"></div>
//     </span>
//       {/* <span class="load"></span> */}

//       <ToastContainer />
//     <BrowserRouter basename={process.env.PUBLIC_URL}>
//       <Switch>
//         <Route exact path="/404" component={NotFound} />
//         <Route exact path="/500" component={BackendError} />
//         <Route exact path="/Lockscreen" component={Lockscreen} />
//         <Route exact path="/forgot" component={PasswordReset} />
//         <Route exact path="/signin" component={Signin} />
//         <Route exact path="/signup" component={Signup} />
//         <Route path="/" component={Dashboard} />
//       </Switch>
//     </BrowserRouter>
//   </AppProvider>,
//   document.getElementById("root")
// );

// registerServiceWorker();
