import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import { profileEditFmorValidationSchema } from "../../schema";
import { GET_COUNTRIES, USER_REGISTRATION } from "../../constants/endpoints";
import { toast } from "react-toastify";
import AxiosService from "../../constants/api";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    // marginTop: theme.spacing(1), // Adjust margin as needed
    padding: theme.spacing(1), // Add padding for spacing inside the container
  },
  card: {
    width: "100%", // Ensure the card takes up 100% width
    // maxWidth: 600, // Limit card width for larger screens
    margin: "auto", // Center the card horizontally
    [theme.breakpoints.up("sm")]: {
      // maxWidth: 600, // Adjust maximum width for larger screens if needed
    },
  },
  textField: {
    marginBottom: theme.spacing(2), // Space between each TextField
  },
}));

const AddUser = () => {
  const classes = useStyles();
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    getCountries();
  }, []);

  const getCountries = async () => {
    try {
      const res = await AxiosService.post(GET_COUNTRIES);
      if (res.data.status === "OK") {
        setCountries(res.data.response);
      }
    } catch (error) {
      console.log("Error fetching countries", error);
    }
  };

  // handle Registration
  const handleAddUser = async (data, resetForm) => {
    try {
      const res = await AxiosService.post(USER_REGISTRATION, data);
      if (res.status === 200) {
        resetForm();
        toast.success("Registered successfully.");
      }
    } catch (error) {
      toast.error(error.response.data.message);
      console.log("Error", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      f_name: "",
      l_name: "",
      email: "",
      password: "",
      phone: "",
      c_code: "",
    },
    validationSchema: profileEditFmorValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      handleAddUser(values, resetForm);
    },
  });

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} sm={12} md={12}>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              New User Info
            </Typography>
            <form onSubmit={formik.handleSubmit} aria-autocomplete="false">
              <TextField
                id="f_name"
                name="f_name"
                label="First Name"
                className={classes.textField}
                fullWidth
                margin="normal"
                value={formik.values.f_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete="off"
                error={formik.touched.f_name && Boolean(formik.errors.f_name)}
                helperText={formik.touched.f_name && formik.errors.f_name}
              />
              <TextField
                id="l_name"
                name="l_name"
                label="Last Name"
                className={classes.textField}
                fullWidth
                margin="normal"
                value={formik.values.l_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete="off"
                error={formik.touched.l_name && Boolean(formik.errors.l_name)}
                helperText={formik.touched.l_name && formik.errors.l_name}
              />
              <TextField
                id="phone"
                name="phone"
                label="Phone No."
                className={classes.textField}
                fullWidth
                margin="normal"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete="off"
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                onKeyDown={(e) => {
                  if (
                    !/^[0-9]$/i.test(e.key) &&
                    e.key !== "Backspace" &&
                    e.key !== "Delete" &&
                    e.key !== "ArrowLeft" &&
                    e.key !== "ArrowRight"
                  ) {
                    e.preventDefault();
                  }
                }}
              />
              {/* Country Code Select */}
              <TextField
                id="c_code"
                name="c_code"
                select
                fullWidth
                margin="normal"
                value={formik.values.c_code}
                onChange={formik.handleChange}
                variant="outlined"
                error={formik.touched.c_code && Boolean(formik.errors.c_code)}
                helperText={formik.touched.c_code && formik.errors.c_code}
                autoComplete="off"
                SelectProps={{
                  native: true,
                }}
              >
                <option value="">Select Country Code</option>
                {countries?.map((country) => (
                  <option key={country.code} value={country.code}>
                    {`${country.code} (${country.name})`}
                  </option>
                ))}
              </TextField>
              <TextField
                id="email"
                name="email"
                label="Email address"
                className={classes.textField}
                fullWidth
                margin="normal"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete="new-password"
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                id="password"
                name="password"
                label="Password"
                className={classes.textField}
                type="password"
                fullWidth
                margin="normal"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete="new-password"
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
              >
                Create
              </Button>
            </form>
          </CardContent>
          <Divider />
        </Card>
      </Grid>
    </Grid>
  );
};

export default AddUser;
