import React, { useState, useRef } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  MenuItem,
  Grid,
} from "@mui/material";
import { CSVLink } from "react-csv";
import AxiosServiceLoaderless from "../../constants/api";
import { GET_ALL_USERS } from "../../constants/endpoints";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const UsersCsv = ({ open, handleClose }) => {
  const [selectedRange, setSelectedRange] = useState("");
  const [customRange, setCustomRange] = useState([null, null]);
  const [csvData, setCsvData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [loading, setLoading] = useState(false);
  const csvLink = useRef(null); // Create a ref for the CSVLink component
  const auth = JSON.parse(localStorage.getItem("auth"));

  const handleRangeChange = (e) => {
    setSelectedRange(e.target.value);
  };

  const formatDataForCsv = async (data) => {
    const headers = [
      { label: "ID", key: "user_id" },
      { label: "Name", key: "name" },
      { label: "Email", key: "email" },
      { label: "Phone", key: "phone" },
      { label: "Created", key: "created" },
      { label: "First Name", key: "f_name" },
      { label: "Last Name", key: "l_name" },
      { label: "Username", key: "username" },
    ];

    const rows = data.map((item) => ({
      user_id: item.user_id,
      name: item.name,
      email: item.email,
      phone: item.phone,
      created: new Date(item.created).toLocaleDateString(),
      f_name: item.f_name,
      l_name: item.l_name,
      username: item.username,
    }));

    return { headers, rows };
  };

  const getDataForCsv = async () => {
    setLoading(true);
    let from, to;

    if (selectedRange === "7days") {
      from = new Date();
      from.setDate(from.getDate() - 7);
      to = new Date();
    } else if (selectedRange === "15days") {
      from = new Date();
      from.setDate(from.getDate() - 15);
      to = new Date();
    } else if (selectedRange === "1month") {
      from = new Date();
      from.setMonth(from.getMonth() - 1);
      to = new Date();
    } else {
      from = customRange[0];
      to = customRange[1];
    }

    const payload = {
      api_key: auth?.api_key,
      from: from.toISOString().split("T")[0],
      to: to.toISOString().split("T")[0],
      offset: 1,
      limit: 1000,
      currentPage: 1,
    };

    try {
      const res = await AxiosServiceLoaderless.post(GET_ALL_USERS, payload);
      if (res.status === 200) {
        const { headers, rows } = await formatDataForCsv(
          res.data.response || []
        );
        setHeaders(headers);
        setCsvData(rows);
        setTimeout(() => {
          csvLink.current.link.click();
          handleClose();
        }, 700);
      }
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const CustomInput = ({ value, onClick }) => (
    <TextField
      fullWidth
      margin="dense"
      value={value}
      onClick={onClick}
      label="Select Date Range"
      readOnly
    />
  );

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Download CSV</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select the day range to download CSV.
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                select
                size="small"
                fullWidth
                label="Select Range"
                value={selectedRange}
                onChange={handleRangeChange}
                margin="dense"
              >
                <MenuItem value="7days">Last 7 Days</MenuItem>
                <MenuItem value="15days">Last 15 Days</MenuItem>
                <MenuItem value="1month">Last 1 Month</MenuItem>
                <MenuItem value="custom">Custom Range</MenuItem>
              </TextField>
            </Grid>
            {selectedRange === "custom" && (
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <DatePicker
                  selected={customRange[0]}
                  onChange={(dates) => setCustomRange(dates)}
                  startDate={customRange[0]}
                  endDate={customRange[1]}
                  selectsRange
                  isClearable
                  inline
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button
            color="success"
            variant="contained"
            onClick={getDataForCsv}
            disabled={
              (selectedRange === "custom" &&
                (!customRange[0] || !customRange[1])) ||
              loading
            }
          >
            {loading ? "Loading..." : "Download"}
          </Button>
          <CSVLink
            data={csvData}
            headers={headers}
            filename={`users_${new Date().toISOString()}.csv`}
            className="hidden"
            ref={csvLink}
            // asyncOnClick={true}
          ></CSVLink>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UsersCsv;
