



import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import { profileEditFmorValidationSchema } from "../../schema";
import { UPDATE_USER_PROFILE } from "../../constants/endpoints";
import { toast } from "react-toastify";
import AxiosService from "../../constants/api";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    // marginTop: theme.spacing(1), // Adjust margin as needed
    padding: theme.spacing(1), // Add padding for spacing inside the container
  },
  card: {
    width: "100%", // Ensure the card takes up 100% width
    // maxWidth: 600, // Limit card width for larger screens
    margin: "auto", // Center the card horizontally
    [theme.breakpoints.up("sm")]: {
      // maxWidth: 600, // Adjust maximum width for larger screens if needed
    },
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
}));

const AssessmentDetail = () => {
  const classes = useStyles();

  const [auth, setAuth] = useState(JSON.parse(localStorage.getItem("auth")));


  const tasks =[
    {"id":"1a", "question_id":"1", "title":"Using a bamboo toothbrush"},
    {"id":"1b", "question_id":"2", "title":"Using biodegradable personal care products and reusable cotton rounds"},
    {"id":"1c","question_id":"3",  "title":"Using natural deodorant"},
    {"id":"1d","question_id":"4", "title":"Reducing food waste by meal planning"},
    {"id":"1e", "question_id":"4", "title":"Choosing seasonal and local produce"},
    {"id":"1f","question_id":"5", "title":"Using reusable food wraps instead of plastic wrap"},
    {"id":"1g","question_id":"6", "title":"Using reusable containers"},
    {"id":"1h", "question_id":"7","title":"Composting food scrapse"},
    {"id":"1i", "question_id":"8","title":"Avoiding food delivery packaging"},
    {"id":"1j", "question_id":"9","title":"Eating organic food"},
    {"id":"1k", "question_id":"10","title":"Reducing meat consumption"}
]

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} sm={12} md={12}>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h4" gutterBottom>
          Assessment Detail  
            </Typography>
     { tasks?.map((task,id) =>(
  <Typography variant="h6" gutterBottom>
  Q {task.question_id}  {task.title} <br/>
</Typography>
      ))}

       
          
          </CardContent>
          <Divider />
        </Card>
      </Grid>
    </Grid>
  );
};

export default AssessmentDetail;
