import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button"; // Adjusted import for Button
import { Avatar, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { GET_USER_ASSESSMENT } from "../../constants/endpoints";
import AxiosService, { AxiosServiceLoaderless } from "../../constants/api";
import { useParams } from "react-router-dom";

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    overflowX: "auto",
  },
  subroot: {
    margin: theme.spacing(2),
    overflowX: "auto",
  },
  table: {
    // marginRight:"20px"
  },
  title: {
    flex: "0 0 auto",
    padding: "20px",
  },
  stickyHeader: {
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: theme.zIndex.appBar + 1, // Ensures it stays on top
  },
  center: {
    alignItem: "center",
  },
});

const ActiveUserDetail = ({ classes }) => {
  const history = useHistory();

  const auth = JSON.parse(localStorage.getItem("auth"));
  const [rows, setRows] = useState([]);

  const getUsersAssessment = async (data) => {
    try {
      const res = await AxiosService.post(GET_USER_ASSESSMENT, data);
      setRows(res.data.response || []);
    } catch (error) {
      // toast.error(error?.response?.data?.message);
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    const url = window.location.href;
    const parts = url.split("/");
    const extractedUserId = parts[parts.length - 1];
    const data = { api_key: auth?.api_key, user_id: extractedUserId };
    getUsersAssessment(data);
  }, []);

  return (
    <Paper className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h4" id="tableTitle">
          User Assesment Detail
        </Typography>
      </div>
      <Paper className={classes.subroot}>
        <Table className={classes.table}>
          <TableHead className={classes.stickyHeader}>
            <TableRow>
              <TableCell>Ass. ID</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Question</TableCell>
              <TableCell>Remarks</TableCell>
              <TableCell>Image</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length < 1 && (
              <TableRow>
                <Typography
                  variant="h6"
                  id="tableTitle"
                  style={{ marginLeft: "10px" }}
                >
                  No Record Found
                </Typography>
              </TableRow>
            )}

            {rows.map((row) => (
              <TableRow key={row.assessment_id}>
                <TableCell>{row.assessment_id}</TableCell>
                <TableCell>{row.category}</TableCell>
                <TableCell>{row.question}</TableCell>
                <TableCell>{row.remarks}</TableCell>
                <TableCell>
                  <Avatar
                    alt={row.name}
                    src={`${process.env.REACT_APP_IMAGE_BASE_URL}${row.img_path}`}
                  />
                  {console.log(row)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Paper>
  );
};

export default withStyles(styles)(ActiveUserDetail);
