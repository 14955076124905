import React, { useEffect, useRef, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button"; // Adjusted import for Button
import {
  Avatar,
  Box,
  Typography,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import AxiosService, { AxiosServiceLoaderless } from "../../constants/api";
import { GET_USER_ASSESSMENT } from "../../constants/endpoints";
import SearchForm from "../SearchForm/SearchForm";
import PaginationFooter from "../PaginationFooter/PaginationFooter";
import CircularProgress from "@mui/material/CircularProgress";
import ImageIcon from "@material-ui/icons/Image";
import { CSVLink } from "react-csv";

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    overflowX: "auto",
  },
  subroot: {
    margin: theme.spacing(2),
    overflowX: "auto",
  },
  tableContainer: {
    maxHeight: "55vh", // Set the maximum height for the table body
    overflowY: "auto", // Enable vertical scrolling
  },
  table: {
    // marginRight:"20px"
  },
  title: {
    flex: "0 0 auto",
    padding: "20px",
  },
  loadingCell: {
    textAlign: "center",
    padding: theme.spacing(2),
  },
  dialogImage: {
    width: "100%",
    minWidth: "300px",
    minHeight: "300px",
    height: "auto",
  },
});

const limit = parseInt(process.env.REACT_APP_TABLE_ROW_LIMIT) || 100;
const fallbackImage = "https://via.placeholder.com/600x400";

const AllAssessments = ({ classes }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([
    {
      name: "user_id",
      label: "User Name",
      type: "select",
      required: false,
      options: [],
    },
    {
      name: "category",
      label: "Category",
      type: "select",
      required: false,
      options: [
        { value: "Personal Care", label: "Personal Care" },
        { value: "Waste Management", label: "Waste Management" },
        { value: "Office Management", label: "Office Management" },
        { value: "Home Management", label: "Home Management" },
        { value: "Community Engagement", label: "Community Engagement" },
        {
          value: "Acts of Kindness Huminity",
          label: "Acts of Kindness Huminity",
        },
      ],
    },
    {
      name: "createdFrom", // Unique name
      label: "From",
      required: false,
      type: "date",
    },
    {
      name: "createdTo", // Unique name
      label: "To",
      required: false,
      type: "date",
    },
    // {
    //   name: "creationDateRange", // Parent name for the date range
    //   type: "daterange",

    // },
  ]);
  const [data, setData] = useState({
    rows: [],
    currentPage: 1,
    nextOffset: 0,
    lastOffset: 0,
    limit: limit,
    totalPages: 0,
  });
  const [open, setOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [csvData, setCsvData] = useState({
    headers: [],
    data: [],
    isDownloadable: false,
  });
  const [searchFields, setSearchFields] = useState(null);
  const auth = JSON.parse(localStorage.getItem("auth"));
  const csvLink = useRef(null);

  const formatDataForCsv = async (data) => {
    const headers = [
      { label: "Assessment ID", key: "assessment_id" },
      { label: "User ID", key: "user_id" },
      { label: "User Name", key: "username" },
      { label: "Category", key: "category" },
      { label: "Question", key: "question" },
      { label: "Remarks", key: "remarks" },
      { label: "Created", key: "created" },
    ];

    const rows = Array.isArray(data)
      ? data.map((item) => ({
          assessment_id: item.assessment_id,
          user_id: item.user_id,
          username: item.username,
          category: item.category,
          question: item.question,
          remarks: item.remarks,
          created: new Date(item.created).toLocaleDateString(), // Format date as needed
        }))
      : [];

    const isDownloadable = rows.length > 0;

    return { headers, rows, isDownloadable };
  };

  const getUsersAssessment = async (payload) => {
    try {
      const { currentPage, ...payloadData } = payload;
      setLoading(true); // Set loading state to true before fetching data
      const res = await AxiosServiceLoaderless.post(
        GET_USER_ASSESSMENT,
        payloadData
      );
      if (res.status === 200) {
        setData((prev) => ({
          ...prev,
          rows: res.data.response || [],
          nextOffset: res.data.next_offset || 0,
          lastOffset: res.data.last_offset || 0,
          totalPages: res.data.total_records
            ? Math.ceil(parseInt(res.data.total_records) / parseInt(data.limit))
            : 0,
          currentPage: currentPage,
          totalRecords: res.data.total_records || 0,
        }));
        let updatedFields = fields.map((field) => {
          if (field.name === "user_id") {
            field.options = res.data.userlist;
          }
          return field;
        });
        // make download button visible, if total_records > 0
        if (res?.data?.total_records && res?.data?.total_records > 0) {
          setCsvData((prev) => ({ ...prev, isDownloadable: true }));
        } else {
          setCsvData((prev) => ({ ...prev, isDownloadable: false }));
        }
        setFields(updatedFields);
      }
    } catch (error) {
      console.log("Error:", error);
      setData((prev) => ({
        ...prev,
        rows: [],
        nextOffset: 0,
        lastOffset: 0,
        totalPages: 0,
        currentPage: payload.currentPage,
        limit: limit,
        totalRecords: 0,
      }));
      setCsvData((prev) => ({ headers: [], data: [], isDownloadable: false }));
    } finally {
      setLoading(false); // Set loading state to false after data fetching
    }
  };

  const handlePageChange = (pageNo) => {
    let payload = {
      api_key: auth?.api_key,
      limit: data.limit,
      offset: (pageNo - 1) * data.limit,
      currentPage: pageNo,
    };
    if (searchFields && Object.keys(searchFields)) {
      // if search fields are filled then update payload for pagination
      payload = { ...payload, ...searchFields };
    }
    getUsersAssessment(payload);
  };

  const handleSearch = (values) => {
    const data = {
      api_key: auth?.api_key,
      user_id: values.user_id,
      from: values.createdFrom,
      to: values.createdTo,
      category: values.category,
      offset: 0,
      limit: limit,
      currentPage: 1,
    };
    // manage search fields in state
    setSearchFields(values);
    getUsersAssessment(data);
  };

  const handleImageClick = (src) => {
    setImageSrc(src);
    setOpen(true);
  };

  const handleCsv = async () => {
    try {
      let payloadData = {
        api_key: auth?.api_key,
        offset: 0,
        limit: data.totalRecords,
      };

      if (searchFields && Object.keys(searchFields)) {
        // add search fields to filter data for csv
        payloadData = { ...payloadData, ...searchFields };
      }

      const res = await AxiosServiceLoaderless.post(
        GET_USER_ASSESSMENT,
        payloadData
      );

      if (res.status === 200) {
        const { headers, rows, isDownloadable } = await formatDataForCsv(
          res.data.response || []
        );
        setCsvData({ headers, data: rows, isDownloadable: isDownloadable });
        setTimeout(() => {
          csvLink.current.link.click();
        }, 700);
      }
    } catch (error) {
      console.log("Error::", error);
      setCsvData({ headers: [], data: [], isDownloadable: false });
    }
  };

  // const handleImageError = () => {
  //   setImageSrc(fallbackImage);
  // };

  useEffect(() => {
    const payload = {
      api_key: auth?.api_key,
      limit: data.limit,
      offset: data.nextOffset,
      currentPage: 1,
    };
    getUsersAssessment(payload);
  }, []);

  return (
    <>
      <Paper className={classes.root}>
        <div className={classes.title}>
          <Typography variant="h4" id="tableTitle">
            All Assessments
          </Typography>
        </div>
        <SearchForm
          fields={fields}
          onSubmit={handleSearch}
          handleCsv={handleCsv}
          isDownloadable={csvData.isDownloadable}
        />
        <Paper className={classes.subroot}>
          {/* <Box className={classes.tableContainer}> */}
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Ass. ID</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Question</TableCell>
                <TableCell>Remarks</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Image</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={7} className={classes.loadingCell}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {data?.rows?.length < 1 && (
                    <TableRow>
                      <TableCell colSpan={7} className={classes.loadingCell}>
                        <Typography variant="h6">No Record Found</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {data?.rows?.map((row) => (
                    <TableRow key={row.assessment_id}>
                      <TableCell>{row.assessment_id}</TableCell>
                      <TableCell>{row.username}</TableCell>
                      <TableCell>{row.category}</TableCell>
                      <TableCell>{row.question}</TableCell>
                      <TableCell>{row.remarks}</TableCell>
                      <TableCell>{row.created}</TableCell>
                      <TableCell>
                        {row.image_path ? (
                          <Box sx={{ cursor: "pointer" }}>
                            <Avatar
                              alt={row.assessment_id}
                              src={`${row.image_path}`}
                              onClick={() =>
                                handleImageClick(`${row.image_path}`)
                              }
                              variant="rounded"
                            >
                              <ImageIcon />
                            </Avatar>
                          </Box>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </TableBody>
          </Table>
          {/* </Box> */}
          <PaginationFooter
            currentPage={data.currentPage}
            totalPages={data.totalPages}
            onPageChange={handlePageChange}
          />
        </Paper>
        <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md">
          <DialogContent>
            <img
              src={imageSrc}
              // alt="Assessment"
              // onError={handleImageError}
              className={classes.dialogImage}
            />
          </DialogContent>
        </Dialog>
        <CSVLink
          data={csvData?.data || data?.rows}
          headers={csvData?.headers}
          filename={`assessments_${new Date().toISOString()}.csv`}
          className="hidden"
          ref={csvLink}
        ></CSVLink>
      </Paper>
    </>
  );
};

export default withStyles(styles)(AllAssessments);
