import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import { profileEditFmorValidationSchema } from "../../schema";
import { UPDATE_USER_PROFILE } from "../../constants/endpoints";
import { toast } from "react-toastify";
import AxiosService from "../../constants/api";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    // marginTop: theme.spacing(1), // Adjust margin as needed
    padding: theme.spacing(1), // Add padding for spacing inside the container
  },
  card: {
    width: "100%", // Ensure the card takes up 100% width
    // maxWidth: 600, // Limit card width for larger screens
    margin: "auto", // Center the card horizontally
    [theme.breakpoints.up("sm")]: {
      // maxWidth: 600, // Adjust maximum width for larger screens if needed
    },
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
}));

const Profile = () => {
  const classes = useStyles();

  const [auth, setAuth] = useState(JSON.parse(localStorage.getItem("auth")));

  const handleProfile = async (data) => {
    
    try {
      const res = await AxiosService.post(UPDATE_USER_PROFILE, data);
      if (res?.data?.status == "OK") {
        auth.f_name = data.f_name;
        auth.email = data.email;
        auth.password = data.password;

        // Save the updated auth object back to local storage
        localStorage.setItem("auth", JSON.stringify(auth));

        // localStorage.setItem("auth", JSON.stringify(res?.data?.response));
        // setIsAuthenticated(true);
        toast.success("Updated successfully.");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log("Error:", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      f_name: auth?.f_name,
      email: auth.email,
      password: auth.password,
      phone: auth.phone,
      // user_id: auth.user_id,
      c_code: "91",
    },
    validationSchema: profileEditFmorValidationSchema,
    onSubmit: (values) => {
      if (
        values.f_name == auth.f_name &&
        values.email == auth.email &&
        values.password == auth.password
      ) {
        toast.error("Looks like you didn't make any update.");
      } else {
        const updatedValues = { ...values, api_key: auth.api_key, user_id : auth.user_id}; 
        handleProfile(updatedValues);
      }
    },
  });

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} sm={12} md={12}>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              General Info 
            </Typography>
            <Typography variant="h7" gutterBottom>
              User Id {auth.user_id}
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                id="f_name"
                name="f_name"
                label="First Name"
                className={classes.textField}
                fullWidth
                margin="normal"
                value={formik.values.f_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.f_name && Boolean(formik.errors.f_name)}
                helperText={formik.touched.f_name && formik.errors.f_name}
              />
              <TextField
                id="phone"
                name="phone"
                label="Phone No."
                className={classes.textField}
                fullWidth
                margin="normal"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  readOnly: true,
                }}
                onKeyDown={(e) => {
                  if (
                    !/^[0-9]$/i.test(e.key) &&
                    e.key !== "Backspace" &&
                    e.key !== "Delete" &&
                    e.key !== "ArrowLeft" &&
                    e.key !== "ArrowRight"
                  ) {
                    e.preventDefault();
                  }
                }}
              />
              {/* <TextField
                id="user_id"
                name="user_id"
                label="User Id"
                className={classes.textField}
                fullWidth
                inputProps={{
                  readOnly: true,
                }}
                margin="normal"
                value={formik.values.user_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.user_id && Boolean(formik.errors.user_id)}
                helperText={formik.touched.user_id && formik.errors.user_id}
              /> */}
              <TextField
                id="email"
                name="email"
                label="Email address"
                className={classes.textField}
                fullWidth
                margin="normal"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                id="password"
                name="password"
                label="Password"
                className={classes.textField}
                type="password"
                fullWidth
                margin="normal"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
              >
                Edit Profile
              </Button>
            </form>
          </CardContent>
          <Divider />
        </Card>
      </Grid>
    </Grid>
  );
};

export default Profile;
